// Import your CSS/SCSS files here:

body {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 17px;
}
h1 {
  font-family: "Covered By Your Grace", cursive;
}
.covered-font {
  font-family: "Covered By Your Grace", cursive;
}
.title-font {
  font-size: responsive 60px 118px;
  line-height: responsive 118px 216px;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  // max-width: calc(1180px + 40px);
  padding: 0 20px;
  @media (min-width: 800px) {
    padding: 0 60px;
  }
  @media (min-width: 1200px) {
    padding: 0 120px;
  }
}
h1,
.h1 {
  font-size: responsive 30px 55px;
  line-height: responsive 34px 64px;
}

h2,
.h2 {
  font-size: responsive 30px 57px;
  line-height: responsive 30px 57px;
  font-weight: 800;
  @apply mb-8;
  strong {
    font-weight: 700;
  }
}
h3,
.h3 {
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
}
h4 {
  line-height: 22px;
}
.bg-text {
  position: absolute;
  font-family: Covered By Your Grace, cursive, sans-serif;
  font-size: 36vw;
  color: #f8f8f8;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  text-align: center;
}
.bg-text.contact {
  top: -5%;
}
.fixed-box {
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
}
.menu {
  .bg-text {
    top: -10%;
    color: #151515;
    width: 93vw;
    overflow: hidden;
    @media (max-width: 600px) {
      top: 0;
    }
  }
}
.text-block {
  p {
    margin-bottom: 15px;
  }
}
.white-svg {
  path {
    fill: white;
  }
}
.image-gallery-wall {
  background: white;
}
section.image-gallery-wall {
  position: relative;
  height: 100vh;
  will-change: transform;
  overflow: hidden;
}
section.image-gallery-wall .image-gallery {
  position: absolute;
  top: -100%;
  left: -100%;
  width: 300%;
  height: 300%;
}
section.image-gallery-wall .image {
  position: absolute;
}
section.image-gallery-wall .image img {
  height: calc(100% - 120px);
  width: calc(100% - 120px);
  margin-top: 60px;
  margin-left: 60px;
  object-fit: cover;
  display: block;
}
section.image-gallery-wall .image#image-gallery-image-1 {
  width: 33.333%;
  height: 33.333%;
  top: 33.3333%;
  left: 33.3333%;
  @media (max-width: 600px) {
    width: 63.333%;
    height: 33.333%;
    top: 33.3333%;
    left: 20.3333%;
  }
}
section.image-gallery-wall .image#image-gallery-image-2 {
  width: 16.666%;
  height: 33.333%;
  top: 50%;
  left: 16.666%;
  @media (max-width: 600px) {
    display: none;
  }
}
section.image-gallery-wall .image#image-gallery-image-3 {
  width: 16.666%;
  height: 33.333%;
  top: 22.666%;
  left: 66.6666%;
  @media (max-width: 600px) {
    width: 36.666%;
    height: 20.333%;
    top: 12.666%;
    left: 46.6666%;
  }
}
section.image-gallery-wall .image#image-gallery-image-4 {
  width: 25%;
  height: 25%;
  top: 25%;
  left: 8.333%;
  @media (max-width: 600px) {
    width: 42%;
    height: 20%;
    top: 15%;
    left: 8.333%;
  }
}
section.image-gallery-wall .image#image-gallery-image-5 {
  width: 16.6666%;
  height: 33.3333%;
  top: 66.666%;
  left: 33.3333%;
  @media (max-width: 600px) {
    width: 46.6666%;
    height: 33.3333%;
    top: 65.666%;
    left: 43.3333%;
  }
}
section.image-gallery-wall .image#image-gallery-image-6 {
  width: 16.6666%;
  height: 16.6666%;
  top: 66.6666%;
  left: 50%;
  @media (max-width: 600px) {
    top: 66.666%;
    left: 5.3333%;
    width: 36.6666%;
    height: 26.6666%;
  }
}
section.image-gallery-wall .image#image-gallery-image-7 {
  width: 25%;
  height: 33.333%;
  top: 56%;
  left: 66.666%;
  @media (max-width: 600px) {
    display: none;
  }
}
.description {
  h4 {
    font-size: 24px;
    margin: 20px 0;
  }
}
.contact-form {
  label {
    font-family: "Covered By Your Grace", cursive;
    text-transform: uppercase;
    text-align: left;
    font-size: 30px;
  }
  input,
  textarea {
    background: #f0f0f0 !important;
    border-radius: 10px;
  }
}
.details {
  h4 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
.top-navigation {
  position: fixed;
  z-index: 100;
  width: calc(100% - 2.5rem);
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 640px) {
    width: 100%;
  }
}
.sticky-menu-nav {
  color: white;
  a {
    color: white;
    font-family: "Covered By Your Grace", cursive;
    font-size: 30px;
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
  }
  a.active {
    color: #ffc000;
  }
}

.headroom--not-top {
  top: 0;
}
.top-nav {
  li {
    a {
      text-transform: uppercase;
      font-weight: 800;
      padding: 0 15px;
      @media (max-width: 600px) {
        font-size: 13px;
        padding: 0 10px;
      }
      @media (max-width: 500px) {
        font-size: 11px;
        padding: 0 7px;
      }
    }
  }
}
section {
  .menu:nth-of-type(2) {
    z-index: 20;
  }
  .menu:nth-of-type(3) {
    z-index: 19;
  }
  .menu:nth-of-type(4) {
    z-index: 18;
  }
}
